@import "~antd/es/style/themes/default.less";
@import "~antd/dist/antd.less";

@font-size-base: 12px; // major text font size

#root {
  display: contents;
}

.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: yellow;
}

.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper
  > input.ant-input,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover
  > input.ant-input {
  background-color: transparent;
}
